import emails from "./patients/emails";
import contacts from "./patients/contacts";
import address from "./patients/address";
import phones from "./patients/phones";
import notes from "./patients/notes";
import providers from "./patients/providers";
import documents from "./patients/documents";
import maxhours from "./patients/maxhours";
import symptoms from "./patients/symptoms";
import symptomsDiary from "./patients/symptomsDiary";
import dashboard from "./patients/dashboard";

import router from "../router";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export default {
  namespaced: true,
  state: {
    data: [],
    calendarPatients: [],
    patient: null,
    isLoading: false,
    isSaving: false,
    tempData: [],
    invoicePatients: [],
    patientUsers: {
      loading: false,
      pagination: {
        current: null,
        total: null,
        data: [],
      },
    },
    budgetUpdates: {
      loading: false,
      data: [],
    },
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_PATIENT_USER_LOADING: (state, value) => {
      state.patientUsers.loading = value;
    },
    SET_PAGINATION: (state, value) => {
      state.patientUsers.pagination = value;
    },
    SET_BUDGET_UPDATES_DATA: (state, data) => {
      state.budgetUpdates.data = data;
    },
    SET_BUDGET_UPDATES_LOADING: (state, data) => {
      state.budgetUpdates.loading = data;
    },
  },
  actions: {
    create: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .post("patients", data)
          .then((res) => {
            let patient = res.data.data;
            context.commit("SET_STATE", { key: "isSaving", value: false });
            Swal.fire({
              title: "",
              text: "Client Record Created Successfully",
              icon: "success",
            });
            router.push({ name: "patients.basic", params: { id: patient.id } });
            return resolve(true);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isSaving", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              return resolve();
            }
          });
      });
    },
    update: function (context, data) {
      let vm = this;
      return new Promise(function (resolve) {
        if (context.state.patient.id) {
          context.commit("SET_STATE", { key: "isSaving", value: true });
          vm.$http
            .put("patients/" + context.state.patient.id, data)
            .then((res) => {
              if (res) {
                Swal.fire({
                  title: "",
                  text: "Client Record Updated Successfully",
                  icon: "success",
                });
                context.commit("SET_STATE", { key: "isSaving", value: false });
                resolve();
              }
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isSaving", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            });
        } else {
          resolve();
        }
      });
    },
    importClients: function (_, data) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .post("patients/import-clients", data)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(err.data || err);
            }
          });
      });
    },
    updatePhoto: function (context, data) {
      context.commit("SET_STATE", { key: "isSaving", value: true });
      let formData = new FormData();
      formData.append("profile_picture", data.profile_picture);
      this.$http
        .post(`patients/${context.state.patient.id}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res) {
            Swal.fire({
              title: "Image Updated",
              text: "Image has been updated successfully",
              icon: "success",
            });

            context.commit("SET_STATE", { key: "isSaving", value: false });
          }
        })
        .catch((err) => {
          context.commit("SET_STATE", { key: "isSaving", value: false });
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text:
                (err.data || err.response.data || {}).message ||
                "Something went wrong...",
              icon: "error",
            });
          }
        });
    },
    showConfidentialData: function (context, requestData) {
      let vm = this;
      this.state.sinSecured = true;
      let id = context.state.patient.id;
      return new Promise(function (resolve, reject) {
        vm.$http
          .post(`patients/${id}/reveal-attribute`, requestData)
          .then((reveralResponse) => {
            if (reveralResponse.status == 200) {
              resolve(reveralResponse.data);
            }
            reject(reveralResponse.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              reject(err.data.error || err.data);
            } else {
              reject(err);
            }
          });
      });
    },
    get: function (context, payload) {
      let { id, noLoading, sin, healthcard } = payload || {};
      let vm = this;
      return new Promise(function (resolve) {
        if (!noLoading) {
          context.commit("SET_STATE", { key: "isLoading", value: true });
        }
        if (typeof id == "undefined") {
          vm.$http
            .get("patients?Active=1")
            .then((res) => {
              if (res.status == 200) {
                context.commit("SET_STATE", {
                  key: "data",
                  value: res.data.data,
                });

                context.commit("SET_STATE", {
                  key: "tempData",
                  value: res.data.data,
                });
              }
              context.commit("SET_STATE", { key: "isLoading", value: false });
              resolve(res.data.data);
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isLoading", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            });
        } else {
          vm.$http
            .get(`patients/${id}`)
            .then((res) => {
              if (sin) {
                res.data.data.sin = sin;
              }
              if (healthcard) {
                res.data.data.healthcard = healthcard;
              }

              if (res.data.data.injured_at) {
                res.data.data.injured_at = dayjs(
                  res.data.data.injured_at.split("T")[0]
                )
                  .add(
                    dayjs(new Date(res.data.data.injured_at)).utcOffset(),
                    "minute"
                  )
                  .utc()
                  .format();
              }
              if (res.data.data.user && res.data.data.user.birth_date) {
                res.data.data.user.birth_date = dayjs(
                  res.data.data.user.birth_date.split("T")[0]
                )
                  .add(
                    dayjs(new Date(res.data.data.user.birth_date)).utcOffset(),
                    "minute"
                  )
                  .utc()
                  .format();
              }
              context.commit("SET_STATE", {
                key: "patient",
                value: res.data.data,
              });
              context.commit("SET_STATE", { key: "isLoading", value: false });
              resolve(res.data.data);
            })
            .catch((err) => {
              context.commit("SET_STATE", { key: "isLoading", value: false });
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    (err.data || err.response.data || {}).message ||
                    "Something went wrong...",
                  icon: "error",
                });
                resolve();
              }
            });
        }
      });
    },
    getInvoicePatients: function (context) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get("invoice-patients")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "invoicePatients",
                value: res.data.data,
              });
            }
            resolve(res.data.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getCalendarPatients: function (context, payload) {
      let vm = this;
      return new Promise(function (resolve) {
        if (!payload || !payload.noLoading) {
          context.commit("SET_STATE", { key: "isLoading", value: true });
        }
        vm.$http
          .get("calendar-patients")
          .then((res) => {
            if (res.status == 200) {
              context.commit("SET_STATE", {
                key: "calendarPatients",
                value: res.data.data,
              });
            }
            context.commit("SET_STATE", { key: "isLoading", value: false });
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    setLoading: function ({ commit }, value) {
      commit("SET_PATIENT_USER_LOADING", value);
    },
    getBasicPatients: function ({ commit, state }, payload = {}) {
      let url = `basic-patients?`;
      if (payload.status || payload.status == 0) {
        url = `${url}status=${payload.status}`;
      }
      if (payload.limit) {
        url = `${url}&limit=${payload.limit}`;
      }
      if (payload.page) {
        url = `${url}&page=${payload.page}`;
      }
      if (payload.administratorId) {
        url = `${url}&administratorId=${payload.administratorId}`;
      }
      if (payload.classIds) {
        url = `${url}&classIds=${payload.classIds}`;
      }
      if (payload.referralId) {
        url = `${url}&referralId=${payload.referralId}`;
      }
      if (payload.address) {
        url = `${url}&Address=${payload.address}`;
      }
      if (payload.username) {
        url = `${url}&username=${payload.username}`;
      }
      if (payload.providerId) {
        url = `${url}&providerId=${payload.providerId}`;
      }
      if (!payload.page) {
        commit("SET_PATIENT_USER_LOADING", true);
      }
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              if (!payload.noStore) {
                if (payload && payload.page) {
                  commit("SET_PAGINATION", {
                    total: res.data.meta.total,
                    current: res.data.meta.current_page,
                    data: [
                      ...state.patientUsers.pagination.data,
                      ...res.data.data,
                    ],
                  });
                } else if (payload && payload.limit) {
                  commit("SET_PAGINATION", {
                    total: res.data.meta.total,
                    current: res.data.meta.current_page,
                    data: res.data.data,
                  });
                } else {
                  commit("SET_PAGINATION", {
                    total: 0,
                    current: 0,
                    data: res.data.data,
                  });
                }
              }
              resolve(res.data.data);
            }
            commit("SET_PATIENT_USER_LOADING", false);
          })
          .catch((err) => {
            commit("SET_PATIENT_USER_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  ((err || {}).data || {}).message || "Something went wrong...",
                icon: "error",
              });
              resolve();
            }
          });
      });
    },
    getBudgetUpdates: function ({ commit }) {
      commit("SET_BUDGET_UPDATES_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .get(`budget-updates`)
          .then((res) => {
            if (res.status == 200) {
              commit(`SET_BUDGET_UPDATES_DATA`, res.data.data);
              commit("SET_BUDGET_UPDATES_LOADING", false);
              resolve(res);
            }
          })
          .catch((err) => {
            commit("SET_BUDGET_UPDATES_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
  },
  modules: {
    emails,
    phones,
    notes,
    address,
    providers,
    documents,
    contacts,
    maxhours,
    symptoms,
    symptomsDiary,
    dashboard,
  },
};
